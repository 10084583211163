import { useEffect, useState, useCallback, useRef } from "react";
import { useReactFlowContext } from "../Context/reactFlowContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import CollapsibleBar from "./CollapsibleBar";
import CustomDropdown from "./CustomDropdown";
import CustomCheckboxList from "./CustomCheckboxList";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { removeSpecialSymbols } from "../Logic/cleanNames";
import {
  DATA,
  DATABASE,
  SCHEMA,
  SOURCE_TABLE,
  LABEL,
  PROCEDURE_NAME,
  TABLE_ABBREVIATION,
  TABLE_REFERENCE,
  OBJECT_SCHEMA,
  OBJECT,
  SELECTED_COLUMNS,
  BUSINESS_KEY,
  SKIP_IN_SYSHASH,
  SYS_SEQUENCER,
  SOURCE_DELETED_FIELD_INDICATOR,
  TALEND_DELETED_FIELD_INDICATOR,
  SOURCE_DELETED_FIELD_NAME,
  SATELLITE_UPDATED_LABEL,
  HUB_NAME_UPDATED_LABEL,
  XLINK_NAME_UPDATED_LABEL,
  DRIVING_KEY_LABEL,
  USER_SETTINGS,
  DRIVING_KEY,
  HUB_CONNECTIONS,
  DRAGGED_CONNECTION,
  CONNECTED_ENTITIES,
  CONNECTION_TYPE,
  OVERLOAD_SATELLITE_TABLES_LIST,
  OVERLOAD_SOURCE_TABLES_OBJECT,
  DATABASE_LIST,
  HUB_ORDER,
} from "../Components/ReactFlowRenderer/CustomNodes/NodeLinkTypes";
import { useModalContext } from "../Context/ModalContext";
import { useDesignsContext } from "../Context/DesignsContext";
import { useSocketioContext } from "../Context/SocketioContext";
import { useAuth } from "../Context/AuthContext";
import { USER_TUTORIAL } from "../Types/MenuTypes";
import ColumnSettingsModal from "./ColumnSettingsModal";
import { ACTION_MESSAGES } from "../Types/ActionMessageTypes";

const NodeSettingsModal = ({ elements, setElements }) => {
  const { updateDesign, currentDesign } = useDesignsContext();
  const {
    nodeSettingsModalShow,
    setNodeSettingsModalShow,
    nodeSettingsModalCurrentNode,
    setNodeSettingsModalCurrentNode,
  } = useModalContext();
  const { getNodeById, getLsatConnections } = useReactFlowContext();
  const { socket } = useSocketioContext();
  const { user, snowflakeAccount, accountsList } = useAuth();
  const [objectSchemasList, setObjectSchemasList] = useState([]);
  const [objectsList, setObjectsList] = useState([]);
  const [columnsList, setColumnsList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedColumnsList, setSelectedColumnsList] = useState([]);
  const [businessKeyColumnList, setBusinessKeyColumnList] = useState([]);
  const [skipSyshashColumnList, setSkipSyshashColumnList] = useState([]);
  const [sysSequencerColumnList, setSysSequencerColumnList] = useState([]);
  const [drivingKeyColumnList, setDrivingKeyColumnList] = useState([]);
  const [linkBusinessKeyObject, setLinkBusinessKeyObject] = useState({});
  const [satelliteRenameIndicator, setSatelliteRenameIndicator] =
    useState(false);
  const [xlinkNameIndicator, setXlinkName] = useState(false);
  const [hubNameIndicator, setHubName] = useState(false);
  const [msatIndicator, setMsatIndicator] = useState(false);
  const [sysHashIndicator, setSysHashIndicator] = useState(false);
  const [overloadSourceTableObject, setOverloadSourceTableObject] = useState(
    {}
  );
  const [nodeSettings, setNodeSettings] = useState({});
  const [nodeSettingsUpdated, setNodeSettingsUpdated] = useState(false);
  const [disableAccordion, setDisableAccordion] = useState(true);
  const [modalCancelClick, setModalCancelClick] = useState(false);
  const columnSettingsRef = useRef()
  const username = `${user?.attributes?.given_name} ${user?.attributes?.family_name}`

  let id, data, type, label, table

  const onSave = () => {
    setNodeSettingsModalShow(false);//true
    setModalCancelClick(false);
  };
  const onHide = () => {
    setNodeSettingsModalShow(false);//false
    setModalCancelClick(true);
  };

  const updateNodeSettingsModal = async () => {
    const settings = {};
    let originalName = nodeSettingsModalCurrentNode[DATA][LABEL]
    if (nodeSettingsModalShow) {
      if (nodeSettingsModalCurrentNode !== null) {
        setNodeSettingsUpdated(false);
        const requestPayload = {
          database: nodeSettingsModalCurrentNode[DATA][DATABASE],
          schema: nodeSettingsModalCurrentNode[DATA][SCHEMA],
          account: accountsList[snowflakeAccount]?.accountURL,
        };
        //Socket event 'getObjectSchemas' requested by client to get object schemas list from server
        socket.emit("getObjectSchemas", requestPayload);
        if (
          nodeSettingsModalCurrentNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !==
          undefined &&
          Object.keys(
            nodeSettingsModalCurrentNode[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]
          ).length > 0
        ) {
          setOverloadSourceTableObject({
            ...nodeSettingsModalCurrentNode[DATA][
            OVERLOAD_SOURCE_TABLES_OBJECT
            ],
          });
        }
        if (nodeSettingsModalCurrentNode[DATA][USER_SETTINGS] !== undefined) {
          setNodeSettings({
            ...nodeSettingsModalCurrentNode[DATA][USER_SETTINGS],
          });

          if (
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][
            SATELLITE_UPDATED_LABEL
            ] !== undefined &&
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][
            SATELLITE_UPDATED_LABEL
            ] !== ""
          )
            setSatelliteRenameIndicator(true);
          if (
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][
            XLINK_NAME_UPDATED_LABEL
            ] !== undefined &&
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][
            XLINK_NAME_UPDATED_LABEL
            ] !== ""
          )
            setXlinkName(true);
          if (
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][
            HUB_NAME_UPDATED_LABEL
            ] !== undefined &&
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][
            HUB_NAME_UPDATED_LABEL
            ] !== ""
          )
            setHubName(true);
          if (
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][SYS_SEQUENCER] !==
            undefined &&
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][SYS_SEQUENCER]
              .length > 0
          )
            setMsatIndicator(true);
          if (
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][
            SKIP_IN_SYSHASH
            ] !== undefined &&
            nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][SKIP_IN_SYSHASH]
              .length > 0
          )
            setSysHashIndicator(true);
        } else {
          setNodeSettings(initializeNodeSettingsState());
        }
      }
    } else {
      setObjectsList([]);
      setColumnsList([]);
      setSelectedColumnsList([]);
      setBusinessKeyColumnList([]);
      setSkipSyshashColumnList([]);
      setSysSequencerColumnList([]);
      setDrivingKeyColumnList([]);
      setLinkBusinessKeyObject({});
      setSatelliteRenameIndicator(false);
      setXlinkName(false);
      setHubName(false);
      setMsatIndicator(false);
      setSysHashIndicator(false);
      
        if (nodeSettingsModalCurrentNode !== null) {
          if(!modalCancelClick){
            const elementList = elements;
            let tableAbbr, tableRef, satUpdatedLabel, hubUpdatedLabel, prevHubName
            elementList.forEach((nodeItem) => {
              if (nodeItem.id === nodeSettingsModalCurrentNode.id) {
                nodeItem[DATA][USER_SETTINGS] = { ...nodeSettings };
                let nodeInitial = nodeItem[DATA][LABEL].split(/_(.*)/s)[0];
                let nodeInitialEnd = nodeItem[DATA][LABEL].split(/_(.*)/s)[1];
                if (nodeInitial === 'COPY') {
                  nodeInitial = nodeInitialEnd.split(/_(.*)/s)[0];
                  nodeInitialEnd = nodeInitialEnd.split(/_(.*)/s)[1];
                }
                tableAbbr = nodeSettings[TABLE_ABBREVIATION]
                tableRef = nodeSettings[TABLE_REFERENCE]

                if (
                  nodeSettings[SATELLITE_UPDATED_LABEL] !== "" &&
                  nodeSettings[SATELLITE_UPDATED_LABEL] !== undefined &&
                  (type !== "hubnode" || type !== "refnode")
                ) {
                  satUpdatedLabel = nodeSettings[SATELLITE_UPDATED_LABEL]
                  nodeItem[DATA][
                    LABEL
                  ] = `${nodeInitial}_${tableAbbr}_${satUpdatedLabel}`;
                  if (type === "satnode" || type === "lsatnode") {
                    nodeItem[DATA][PROCEDURE_NAME] = `PROC_${nodeInitial}_${tableAbbr}_${satUpdatedLabel}_${tableRef}`
                  }
                  else if (type === "xrefnode") {
                    nodeItem[DATA][PROCEDURE_NAME] = `PROC_${nodeInitial}_${tableAbbr}_${satUpdatedLabel}`
                  }
                }
                else {
                  if (type !== "hubnode" && type !== "refnode") {
                    nodeItem[DATA][
                      LABEL
                    ] = `${nodeInitial}_${nodeInitialEnd.toUpperCase()}`;
                    if (type === "xrefnode") {
                      nodeItem[DATA][PROCEDURE_NAME] = `PROC_${nodeInitial}_${tableAbbr}_${nodeInitialEnd.toUpperCase()}`
                    }
                    else {
                      nodeItem[DATA][PROCEDURE_NAME] = `PROC_${nodeInitial}_${nodeInitialEnd.toUpperCase()}`
                    }
                  }
                }

                // if (nodeSettings[XLINK_NAME_UPDATED_LABEL] !== '' && nodeSettings[XLINK_NAME_UPDATED_LABEL] !== undefined && (type === 'xlinknodedelta' || type === 'xlinknodefull')) {
                //   nodeItem[DATA][LABEL] = `${nodeInitial}_${nodeSettings[TABLE_ABBREVIATION]}_${nodeSettings[XLINK_NAME_UPDATED_LABEL]}`
                // }
                // else {
                //   if () {
                //     nodeItem[DATA][LABEL] = `${nodeInitial}_${nodeInitialEnd.toUpperCase()}`
                //   }
                // }
                if (type === "xlinknodedelta" || type === "xlinknodefull") {
                  if (nodeSettings[XLINK_NAME_UPDATED_LABEL] !== "") {
                    if (nodeSettings[XLINK_NAME_UPDATED_LABEL] !== undefined) {
                      nodeItem[DATA][
                        LABEL
                      ] = `${nodeInitial}_${nodeSettings[XLINK_NAME_UPDATED_LABEL]}`;
                      const updatedNodeName = nodeInitial === "XLINK" ? "XLNK" : nodeInitial
                      nodeItem[DATA][PROCEDURE_NAME] = `PROC_${updatedNodeName}_${tableAbbr}_${nodeSettings[XLINK_NAME_UPDATED_LABEL]}_${tableRef}`
                    }
                  }
                  else {
                    nodeItem[DATA][
                      LABEL
                    ] = `${nodeInitial}_${linkHubConnections.join("_")}`;
                  }
                }

                if (
                  nodeSettings[HUB_NAME_UPDATED_LABEL] !== "" &&
                  nodeSettings[HUB_NAME_UPDATED_LABEL] !== undefined
                ) {
                  hubUpdatedLabel = nodeSettings[HUB_NAME_UPDATED_LABEL]
                  prevHubName = nodeInitialEnd
                  nodeItem[DATA][
                    LABEL
                  ] = `${nodeInitial}_${hubUpdatedLabel}`;
                  nodeItem[DATA][PROCEDURE_NAME] = nodeItem[DATA][PROCEDURE_NAME].replace(`_${nodeInitialEnd}_`, `_${hubUpdatedLabel}_`)
                  if (type === "hubnode")
                    handleHubRename(nodeItem.id, nodeItem[DATA][LABEL]);
                }
                else {
                  if (type === "hubnode" || type === "refnode") {
                    nodeItem[DATA][
                      LABEL
                    ] = `${nodeSettingsModalCurrentNode.data.originalLabel.toUpperCase()}`;
                    if (type === "hubnode")
                      handleHubRename(nodeItem.id, nodeItem[DATA][LABEL]);
                  }
                }

                const sysSequencer = nodeSettings[SYS_SEQUENCER];
                const labelName = nodeItem[DATA][LABEL];
                if (sysSequencer !== undefined && sysSequencer.length > 0) {
                  nodeItem[DATA][LABEL] = labelName.replace(/^SAT/, "MSAT");
                }
                else {
                  nodeItem[DATA][LABEL] = labelName.replace(/^MSAT/, "SAT");
                }
                nodeItem[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] = {
                  ...overloadSourceTableObject,
                };
              }
            });

            elementList.forEach((nodeItem) => {
              if ((((nodeItem.type === "hubnode" || nodeItem.type === "refnode") && nodeSettingsModalCurrentNode.type === "satnode") || (nodeSettingsModalCurrentNode.type === "lsatnode" && nodeItem.type === "linknode")) && nodeItem[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES].includes(nodeSettingsModalCurrentNode.id)) {
                const nodeInitial = nodeItem[DATA][LABEL].split(/_(.*)/s)[0];
                const nodeInitialEnd = nodeItem[DATA][LABEL].split(/_(.*)/s)[1];
                if (satUpdatedLabel) {
                  nodeItem[DATA][PROCEDURE_NAME] = `PROC_${nodeInitial}_${tableAbbr}_${nodeInitialEnd}_${satUpdatedLabel}_${tableRef}`.replace("_LINK_", "_LNK_").replace("_XLINK", "_XLNK_")
                }
                else {
                  nodeItem[DATA][PROCEDURE_NAME] = `PROC_${nodeInitial}_${tableAbbr}_${nodeInitialEnd}_${tableRef}`.replace("_LINK_", "_LNK_").replace("_XLINK", "_XLNK_")
                }
              }

              if (hubUpdatedLabel && nodeItem.type === "linknode" && nodeSettingsModalCurrentNode.type === "hubnode" && nodeItem[DATA][HUB_CONNECTIONS][HUB_ORDER].includes(nodeSettingsModalCurrentNode.id)) {
                nodeItem[DATA][PROCEDURE_NAME] = nodeItem[DATA][PROCEDURE_NAME].replace(`_${prevHubName}_`, `_${hubUpdatedLabel}_`).replace("_LINK_", "_LNK_")
              }

            })
            setElements((prevState) => {
              return [...elementList];
            });
            const actionType = "UPDATE_NODE_SETTINGS"
            let isRename = originalName === nodeSettingsModalCurrentNode[DATA][LABEL]
            let message = ACTION_MESSAGES[actionType].message({ user: username,updatedNodeName: nodeSettingsModalCurrentNode[DATA][LABEL], originalNodeName:originalName, isRename: isRename});
            if (nodeSettingsUpdated) updateDesign(message);
          }
          
        
        if (currentDesign?.designId !== USER_TUTORIAL) {
          socket.emit("nodeSettingsClose", {
            userId: `${user?.attributes?.given_name} ${user?.attributes?.family_name}`,
            tableId: currentDesign?.designId,
            nodeId: nodeSettingsModalCurrentNode.id,
          });
        }
          setNodeSettingsModalCurrentNode(null);
        }
      
      
      setOverloadSourceTableObject({});
      setNodeSettings(initializeNodeSettingsState());
    }
  }

  useEffect(async () => {
      updateNodeSettingsModal();
  }, [nodeSettingsModalShow]);

  useEffect(() => {
    if (socket !== null) {
      //Socket event 'receiveObjectSchemas' sent from server with object schemas list as response
      socket.on("receiveObjectSchemas", (responsePayload) => {
        setObjectSchemasList(responsePayload.objectSchemas);
      });

      //Socket event 'receiveObjects' sent from server with object list as response
      socket.on("receiveObjects", (responsePayload) => {
        setObjectsList(responsePayload.objects);
      });

      //Socket event 'receiveColumns' sent from server with columns list as response
      socket.on("receiveColumns", (responsePayload) => {
        setColumnsList(responsePayload.columns);
      });
    }
  }, [socket]);

  const initializeNodeSettingsState = useCallback(() => {
    const settings = {};
    settings[TABLE_ABBREVIATION] = "";
    settings[TABLE_REFERENCE] = "";
    settings[OBJECT_SCHEMA] = "";
    settings[OBJECT] = "";
    settings[SELECTED_COLUMNS] = [];
    settings[BUSINESS_KEY] = [];
    settings[SKIP_IN_SYSHASH] = [];
    settings[SYS_SEQUENCER] = [];
    settings[SOURCE_DELETED_FIELD_INDICATOR] = false;
    settings[TALEND_DELETED_FIELD_INDICATOR] = false;
    settings[SOURCE_DELETED_FIELD_NAME] = "";
    settings[SATELLITE_UPDATED_LABEL] = "";
    settings[HUB_NAME_UPDATED_LABEL] = "";
    settings[XLINK_NAME_UPDATED_LABEL] = "";
    settings[DRIVING_KEY_LABEL] = "";

    return { ...settings };
  }, []);

  const renderSelectedColumnList = (type) => {
    if (selectedColumnsList.length > 0) {
      return (
        <div className="my-2">
          <div className="node-settings-accordion-column-selection">
            <input
              type="checkbox"
              onChange={(event) => handleSelectAll(event.target.checked)}
              checked={checkedAll}
              id="selectAllCheckbox"
            />
            <label for="selectAllCheckbox">Select All</label>

          </div>
          <CustomCheckboxList
            inputGroupName={`${SELECTED_COLUMNS}`}
            checkboxList={selectedColumnsList}
            filterable={true}
            handleCheckboxListChange={handleListChange}
            updateListOrder={setSelectedColumnsList}
            isSortable={true}
            title=""
          />
        </div>

      );
    }
    return null;
  };

  const renderBusinessKeys = (type) => {
    switch (true) {
      case type === "xrefnode" && businessKeyColumnList.length > 0:
        return (
          <div className="my-2">
            <label className="node-settings-label mb-2">
              Select the columns for the Business Key and drag them in the
              desired order
            </label>
            <CustomCheckboxList
              inputGroupName={`${BUSINESS_KEY}`}
              checkboxList={businessKeyColumnList}
              filterable={true}
              handleCheckboxListChange={handleListChange}
              updateListOrder={setBusinessKeyColumnList}
              isSortable={true}
              title="Select data type casts for your columns"
            />
          </div>
        );
      case type === "satnode" && businessKeyColumnList.length > 0:
        if (
          !(
            nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
            CONNECTION_TYPE
            ] === "HUB||SAT||LINK||LSAT"
          )
        )
          return (
            <div className="my-2">
              <label className="node-settings-label mb-2">
                Select the columns for the Business Key and drag them in the
                desired order
              </label>
              <CustomCheckboxList
                inputGroupName={`${BUSINESS_KEY}`}
                checkboxList={businessKeyColumnList}
                filterable={true}
                handleCheckboxListChange={handleListChange}
                updateListOrder={setBusinessKeyColumnList}
                isSortable={true}
                title="Select data type casts for your columns"
              />
            </div>
          );
        break;
      case type === "lsatnode":
        return (
          <div className="my-2">
            <label className="node-settings-label mb-2">
              Select Business Key for Linked HUBs
            </label>
            {Object.keys(linkBusinessKeyObject).length !== 0 &&
              linkHubConnections.map((hubLabel) => {
                return (
                  <Accordion className="node-settings-hub-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="node-settings-hub-accordion-title"
                    >
                      {`HUB_${hubLabel}`}
                    </AccordionSummary>
                    <AccordionDetails className="node-settings-accordion-details">
                      <div className="my-2">
                        <div>
                          Select the columns for the Business Key and drag them
                          in the desired order
                        </div>
                        {linkBusinessKeyObject[hubLabel].length > 0 && (
                          <CustomCheckboxList
                            inputGroupName={`${hubLabel}`}
                            checkboxList={linkBusinessKeyObject[hubLabel]}
                            filterable={true}
                            handleCheckboxListChange={
                              handleBusinessKeyObjectListChange
                            }
                            updateListOrder={setLinkBusinessKeyObject}
                            isSortable={true}
                            title="Select data type casts for your columns"
                          />
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        );
      case type === "xlinknodedelta":
        return (
          <div className="my-2">
            <div>Select Business Key for Linked HUBs</div>
            {Object.keys(linkBusinessKeyObject).length !== 0 &&
              linkHubConnections.map((hubLabel, index) => {
                return (
                  <Accordion
                    key={index}
                    className="node-settings-hub-accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="node-settings-hub-accordion-title"
                    >{`HUB_${hubLabel}`}</AccordionSummary>
                    <AccordionDetails className="node-settings-accordion-details">
                      <div className="my-2">
                        <div>
                          Select the columns for the Business Key and drag them
                          in the desired order
                        </div>
                        {linkBusinessKeyObject[hubLabel].length > 0 && (
                          <CustomCheckboxList
                            inputGroupName={`${hubLabel}`}
                            checkboxList={linkBusinessKeyObject[hubLabel]}
                            filterable={true}
                            handleCheckboxListChange={
                              handleBusinessKeyObjectListChange
                            }
                            updateListOrder={setLinkBusinessKeyObject}
                            isSortable={true}
                            title="Select data type casts for your columns"
                          />
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        );
      case type === "xlinknodefull":
        return (
          <div className="my-2">
            <div>Select Business Key for Linked HUBs</div>
            {Object.keys(linkBusinessKeyObject).length !== 0 &&
              linkHubConnections.map((hubLabel) => {
                return (
                  <Accordion className="node-settings-hub-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="node-settings-hub-accordion-title"
                    >
                      {`HUB_${hubLabel}`}
                    </AccordionSummary>
                    <AccordionDetails className="node-settings-accordion-details">
                      <div className="my-2">
                        <div>
                          Select the columns for the Business Key and drag them
                          in the desired order
                        </div>
                        {linkBusinessKeyObject[hubLabel].length > 0 && (
                          <CustomCheckboxList
                            inputGroupName={`${hubLabel}`}
                            checkboxList={linkBusinessKeyObject[hubLabel]}
                            filterable={true}
                            handleCheckboxListChange={
                              handleBusinessKeyObjectListChange
                            }
                            updateListOrder={setLinkBusinessKeyObject}
                            isSortable={true}
                            title="Select data type casts for your columns"
                          />
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        );
      default:
        return null;
    }
  };

  const renderRenameOrXlinkName = (type) => {
    switch (type) {
      case "satnode":
        return (
          <div className="mb-3">
            <label className="node-settings-label">
              Do you want to rename this table :
            </label>
            <div className="mt-3">
              <Form onSubmit={(event) => event.preventDefault()}>
                {["Yes", "No"].map((value) => {
                  return (
                    <Form.Check
                      inline
                      type="radio"
                      id={`satellite-rename_${value}`}
                      label={value}
                      onChange={handleRadioChange}
                      checked={
                        value === "Yes"
                          ? satelliteRenameIndicator
                          : !satelliteRenameIndicator
                      }
                      disabled={!currentDesign.authorized}
                    />
                  );
                })}
              </Form>
            </div>
          </div>
        );
      case "lsatnode":
        return (
          <div className="mb-3">
            <label className="node-settings-label">
              Do you want to rename this table :
            </label>
            <div className="mt-3">
              <Form onSubmit={(event) => event.preventDefault()}>
                {["Yes", "No"].map((value) => {
                  return (
                    <Form.Check
                      inline
                      type="radio"
                      id={`satellite-rename_${value}`}
                      label={value}
                      onChange={handleRadioChange}
                      checked={
                        value === "Yes"
                          ? satelliteRenameIndicator
                          : !satelliteRenameIndicator
                      }
                    />
                  );
                })}
              </Form>
            </div>
          </div>
        );
      case "xrefnode":
        return (
          <div className="mb-3">
            <label className="node-settings-label">
              Do you want to rename this table :
            </label>
            <div className="mt-3">
              <Form onSubmit={(event) => event.preventDefault()}>
                {["Yes", "No"].map((value) => {
                  return (
                    <Form.Check
                      inline
                      type="radio"
                      id={`satellite-rename_${value}`}
                      label={value}
                      onChange={handleRadioChange}
                      checked={
                        value === "Yes"
                          ? satelliteRenameIndicator
                          : !satelliteRenameIndicator
                      }
                    />
                  );
                })}
              </Form>
            </div>
          </div>
        );
      case "xlinknodefull":
        return (
          <div className="mb-3">
            <label className="node-settings-label">
              Choose a name for the table :
            </label>

            <div className="mt-3">
              <Form
                className="rename-form"
                onSubmit={(event) => event.preventDefault()}
              >
                <InputGroup>
                  <Form.Control
                    type="text"
                    onChange={(event) =>
                      updateNodeSettings(
                        XLINK_NAME_UPDATED_LABEL,
                        event.target.value.toUpperCase()
                      )
                    }
                    value={
                      nodeSettings[XLINK_NAME_UPDATED_LABEL] !== undefined
                        ? nodeSettings[XLINK_NAME_UPDATED_LABEL]
                        : ""
                    }
                    placeholder="Enter Xlink name"
                    autoFocus
                  />
                </InputGroup>
              </Form>
            </div>
          </div>
        );
      case "xlinknodedelta":
        return (
          <div className="mb-3">
            <label className="node-settings-label">
              Choose a name for the table :
            </label>
            <div className="mt-3">
              <Form
                className="rename-form"
                onSubmit={(event) => event.preventDefault()}
              >
                <InputGroup>
                  <Form.Control
                    type="text"
                    onChange={(event) =>
                      updateNodeSettings(
                        XLINK_NAME_UPDATED_LABEL,
                        event.target.value.toUpperCase()
                      )
                    }
                    value={
                      nodeSettings[XLINK_NAME_UPDATED_LABEL] !== undefined
                        ? nodeSettings[XLINK_NAME_UPDATED_LABEL]
                        : ""
                    }
                    placeholder="Enter Xlink name"
                    autoFocus
                  />
                </InputGroup>
              </Form>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const updateNodeSettings = (key, value) => {
    setNodeSettingsUpdated(true);
    const newObject = {};
    newObject[key] = value;
    setNodeSettings((prevState) => {
      return { ...prevState, ...newObject };
    });
  };

  useEffect(() => {
    if (
      nodeSettings[TABLE_ABBREVIATION] !== "" &&
      nodeSettings[TABLE_ABBREVIATION] !== undefined
    ) {
      const tblAbbr = `${nodeSettings[TABLE_ABBREVIATION]}_`;
      RegExp.quote = function (str) {
        return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
      };
      const abbrRegex = new RegExp(RegExp.quote(tblAbbr));
      const tableReferenceValue = nodeSettingsModalCurrentNode[DATA][
        SOURCE_TABLE
      ].replace(abbrRegex, "");
      updateNodeSettings(TABLE_REFERENCE, tableReferenceValue);
    }
  }, [nodeSettings[TABLE_ABBREVIATION]]);

  useEffect(async () => {
    if (!nodeSettings[OBJECT_SCHEMA] || nodeSettingsModalCurrentNode === null) {
      return;
    }
    const requestPayload = {
      account: accountsList[snowflakeAccount]?.accountURL,
      database: nodeSettingsModalCurrentNode[DATA][DATABASE],
      schema: nodeSettingsModalCurrentNode[DATA][SCHEMA],
      object_schema: nodeSettings[OBJECT_SCHEMA],
    };
    //Socket event 'getObjects' requested by client to get object list from server
    socket.emit("getObjects", requestPayload);
  }, [nodeSettings[OBJECT_SCHEMA], nodeSettingsModalCurrentNode]);

  useEffect(async () => {
    if (nodeSettings[OBJECT] !== "" && nodeSettings[OBJECT] !== undefined) {
      const requestPayload = {
        account: accountsList[snowflakeAccount]?.accountURL,
        database: nodeSettingsModalCurrentNode[DATA][DATABASE],
        schema: nodeSettingsModalCurrentNode[DATA][SCHEMA],
        object_schema: nodeSettings[OBJECT_SCHEMA],
        object: nodeSettings[OBJECT],
      };
      //Socket event 'getColumns' requested by client to get columns list from server
      socket.emit("getColumns", requestPayload);
    }
  }, [nodeSettings[OBJECT]]);


  useEffect(() => {
    //If both objectshcema and object is selected and desing not sanbox emit columnCast
    if (nodeSettingsModalShow && currentDesign?.designId !== USER_TUTORIAL && !(type === "hubnode" || type === "refnode") && nodeSettings[OBJECT] && nodeSettings[OBJECT_SCHEMA] && !nodeSettingsModalCurrentNode.data.userSettings && columnSettingsRef?.current) {
      setNodeSettingsModalCurrentNode((prev) => {
        const object = { ...prev };
        object[DATA][USER_SETTINGS] = nodeSettings
        return { ...object };
      })
      columnSettingsRef?.current?.getData();
    }

    // If the basic details are filled enable rest of the accordian

    if (nodeSettingsModalShow && nodeSettings[OBJECT] && nodeSettings[OBJECT_SCHEMA]) {
      setDisableAccordion(false)
    } else {
      setDisableAccordion(true)
    }
  }, [nodeSettings, nodeSettingsModalShow, currentDesign, type, columnSettingsRef, nodeSettingsModalCurrentNode])

  useEffect(() => {
    if (columnsList.length > 0) {
      setSelectedColumnsList(filterColumnList(SELECTED_COLUMNS));
      setBusinessKeyColumnList(filterColumnList(BUSINESS_KEY));
      setSkipSyshashColumnList(filterColumnList(SKIP_IN_SYSHASH));
      setSysSequencerColumnList(filterColumnList(SYS_SEQUENCER));
      setDrivingKeyColumnList(filterColumnList(DRIVING_KEY));
      linkHubConnections?.forEach((hubLabel) => {
        setLinkBusinessKeyObject((prevState) => {
          const object = { ...prevState };
          const filteredList = filterObjectColumnList(hubLabel);
          object[hubLabel] = filteredList;
          return { ...object };
        });
      });
    }
  }, [columnsList]);

  const filterColumnList = (objectName) => {
    let updatedColumnList = [];
    if (
      nodeSettings[objectName] !== undefined &&
      nodeSettings[objectName].length > 0
    ) {
      const checkedOrderList = nodeSettings[objectName].map((columnItem) => ({
        columnItem,
        checked: true,
      }));
      columnsList.forEach((columnItem) => {
        if (!nodeSettings[objectName].includes(columnItem)) {
          updatedColumnList.push({ columnItem, checked: false });
        }
      });
      updatedColumnList.unshift(...checkedOrderList);
    } else {
      if(objectName === SELECTED_COLUMNS){
        // this check is done in case where node was created before multiple sats feature release and so by default we need to select all columns for them (as it is the default behaviour)
        if (nodeSettingsModalCurrentNode[DATA].hasOwnProperty(USER_SETTINGS) && !(nodeSettingsModalCurrentNode[DATA][USER_SETTINGS].hasOwnProperty(SELECTED_COLUMNS))) {
          if ((nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== undefined) && (nodeSettingsModalCurrentNode[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== '')) {
            updatedColumnList = columnsList.map((columnItem) => {
              return { columnItem, checked: true };
            });
            return updatedColumnList;
          }
        }
      }

      // will get here when creating new node and doing nodeSettings
      updatedColumnList = columnsList.map((columnItem) => {
        return { columnItem, checked: false };
      });

    }
    return updatedColumnList;
  };

  const filterObjectColumnList = (hubLabel) => {
    let updatedColumnList = [];
    if (
      nodeSettings[BUSINESS_KEY][hubLabel] !== undefined &&
      nodeSettings[BUSINESS_KEY][hubLabel].length > 0
    ) {
      const checkedOrderList = nodeSettings[BUSINESS_KEY][hubLabel].map(
        (columnItem) => ({ columnItem, checked: true })
      );
      columnsList.forEach((columnItem) => {
        if (!nodeSettings[BUSINESS_KEY][hubLabel].includes(columnItem)) {
          updatedColumnList.push({ columnItem, checked: false });
        }
      });
      updatedColumnList.unshift(...checkedOrderList);
    } else {
      updatedColumnList = columnsList.map((columnItem) => {
        return { columnItem, checked: false };
      });
    }
    return updatedColumnList;
  };

  useEffect(() => {
    const columnKey = [];
    selectedColumnsList.forEach((sortedColumnItem) => {
      if (sortedColumnItem.checked === true) {
        columnKey.push(sortedColumnItem.columnItem);
      }
    });
    updateNodeSettings(SELECTED_COLUMNS, columnKey);
  }, [selectedColumnsList]);

  useEffect(() => {
    const businessKey = [];
    businessKeyColumnList.forEach((sortedColumnItem) => {
      if (sortedColumnItem.checked === true) {
        businessKey.push(sortedColumnItem.columnItem);
      }
    });
    updateNodeSettings(BUSINESS_KEY, businessKey);
  }, [businessKeyColumnList]);

  useEffect(() => {
    const skipSyshash = [];
    skipSyshashColumnList.forEach((sortedColumnItem) => {
      if (sortedColumnItem.checked === true)
        skipSyshash.push(sortedColumnItem.columnItem);
    });
    updateNodeSettings(SKIP_IN_SYSHASH, skipSyshash);
  }, [skipSyshashColumnList]);

  useEffect(() => {
    const sysSequencer = [];
    sysSequencerColumnList.forEach((sortedColumnItem) => {
      if (sortedColumnItem.checked === true)
        sysSequencer.push(sortedColumnItem.columnItem);
    });
    updateNodeSettings(SYS_SEQUENCER, sysSequencer);
  }, [sysSequencerColumnList]);

  useEffect(() => {
    const drivingKey = [];
    drivingKeyColumnList.forEach((sortedColumnItem) => {
      if (sortedColumnItem.checked === true)
        drivingKey.push(sortedColumnItem.columnItem);
    });
    updateNodeSettings(DRIVING_KEY, drivingKey);
  }, [drivingKeyColumnList]);

  useEffect(() => {
    const objectKeys = Object.keys(linkBusinessKeyObject);
    const object = {};
    if (objectKeys.length !== 0) {
      objectKeys.forEach((objectKey) => {
        const checkedList = [];
        linkBusinessKeyObject[objectKey].forEach((sortedColumnItem) => {
          if (sortedColumnItem.checked === true)
            checkedList.push(sortedColumnItem.columnItem);
        });
        object[objectKey] = checkedList;
      });
      updateNodeSettings(BUSINESS_KEY, object);
    }
  }, [linkBusinessKeyObject]);

  useEffect(() => {
    if (Object.keys(overloadSourceTableObject).length > 0)
      setSatelliteRenameIndicator(true);
    else {
      updateNodeSettings(SATELLITE_UPDATED_LABEL, "");
      setSatelliteRenameIndicator(false);
    }
  }, [overloadSourceTableObject]);

  if (nodeSettingsModalCurrentNode === null) {
    return (
      <Modal
        show={nodeSettingsModalShow}
        onHide={onHide}
        size="lg"
        aria-labelledby="node-settings-modal"
        centered
      >
        <Modal.Body>
          <h3>No node selected yet...</h3>
        </Modal.Body>
      </Modal>
    );
  }

  id = nodeSettingsModalCurrentNode.id
  type = nodeSettingsModalCurrentNode.type
  data = nodeSettingsModalCurrentNode.data
  label = data.label
  table = data.table
  // const { id, data, type } = nodeSettingsModalCurrentNode;
  // const { label, table } = data;
  const collapsibleItems = [];
  let linkHubConnections = [];
  let renderItem, separatorList;
  const separatorArr = table.split("_");
  separatorList = separatorArr.map((item, index) => {
    return separatorArr.slice(0, index).join("_");
  });
  separatorList.shift();
  if (type === "lsatnode") {
    var lsatLinkNode = getNodeById(
      nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
      CONNECTED_ENTITIES
      ][0]
    );

    if (
      nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
      CONNECTION_TYPE
      ] === "HUB||SAT||LINK||LSAT"
    ) {
      lsatLinkNode = getNodeById(
        nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
        CONNECTED_ENTITIES
        ][2]
      );
    }
    linkHubConnections = lsatLinkNode[DATA][HUB_CONNECTIONS]["hubOrder"].map(
      (hubId) => {
        return lsatLinkNode[DATA][HUB_CONNECTIONS][hubId].replace(/^HUB_/, "");
      }
    );
  }

  if (type === "xlinknodedelta" || type === "xlinknodefull") {
    const lsatLinkNode = nodeSettingsModalCurrentNode;
    linkHubConnections = lsatLinkNode[DATA][HUB_CONNECTIONS]["hubOrder"].map(
      (hubId) => {
        return lsatLinkNode[DATA][HUB_CONNECTIONS][hubId].replace(/^HUB_/, "");
      }
    );
  }

  const handleHubRename = (hubId, hubRename) => {
    let linkLsatNodesList = [],
      previousName = "";
    elements.forEach((elem) => {
      if (elem[DATA] !== undefined) {
        if (elem[DATA][HUB_CONNECTIONS] !== undefined) {
          if (hubId in elem[DATA][HUB_CONNECTIONS]) {
            previousName = elem[DATA][HUB_CONNECTIONS][hubId];
            elem[DATA][HUB_CONNECTIONS][hubId] = hubRename.replace(/^HUB_/, "");
            elem[DATA][LABEL] = elem[DATA][LABEL].replace(
              previousName,
              hubRename.replace(/^HUB_/, "")
            );
            //Also update the business keys for LINK and XLINK_DELTA and XLINK_FULL
            if (elem.type !== "linknode") {
              if (
                elem[DATA][USER_SETTINGS] !== undefined &&
                elem[DATA][USER_SETTINGS][BUSINESS_KEY] !== undefined &&
                elem[DATA][USER_SETTINGS][BUSINESS_KEY][previousName] !==
                undefined
              ) {
                elem[DATA][USER_SETTINGS][BUSINESS_KEY][
                  hubRename.replace(/^HUB_/, "")
                ] = elem[DATA][USER_SETTINGS][BUSINESS_KEY][previousName];
                delete elem[DATA][USER_SETTINGS][BUSINESS_KEY][previousName];
              }
            } else {
              const lsatConnections = getLsatConnections(elem);
              lsatConnections.forEach((lsatConnection) =>
                linkLsatNodesList.push(lsatConnection.id)
              );
            }
          }
        }
      }
    });
    elements.forEach((elem) => {
      if (linkLsatNodesList.includes(elem.id)) {
        if (
          elem[DATA][USER_SETTINGS] !== undefined &&
          elem[DATA][USER_SETTINGS][BUSINESS_KEY] !== undefined &&
          elem[DATA][USER_SETTINGS][BUSINESS_KEY][previousName] !== undefined
        ) {
          elem[DATA][USER_SETTINGS][BUSINESS_KEY][
            hubRename.replace(/^HUB_/, "")
          ] = elem[DATA][USER_SETTINGS][BUSINESS_KEY][previousName];
          delete elem[DATA][USER_SETTINGS][BUSINESS_KEY][previousName];
        }
      }
    });
  };

  const handleSelectAll = (value) => {
    setCheckedAll(value);
    const updatedSelectedColumnList = [];
    selectedColumnsList.forEach((i) => {
      updatedSelectedColumnList.push({
        columnItem: i.columnItem,
        checked: value,
      });
    });
    setSelectedColumnsList(updatedSelectedColumnList);

  };

  const handleListChange = (updatedItem, updatedValue, inputGroupName) => {
    const updatedColumnList = [];
    let currentList = [];
    switch (inputGroupName) {
      case SELECTED_COLUMNS:
        currentList = selectedColumnsList;
        break;
      case BUSINESS_KEY:
        currentList = businessKeyColumnList;
        break;
      case SKIP_IN_SYSHASH:
        currentList = skipSyshashColumnList;
        break;
      case SYS_SEQUENCER:
        currentList = sysSequencerColumnList;
        break;
      case DRIVING_KEY:
        currentList = drivingKeyColumnList;
        break;
      default:
        break;
    }
    let checkedItemCount = 0;
    currentList.forEach((columnListItem) => {
      if (columnListItem.columnItem !== updatedItem)
        updatedColumnList.push(columnListItem);
      if (columnListItem.checked) checkedItemCount += 1;
    });
    if (updatedValue === true){
      updatedColumnList.splice(checkedItemCount, 0, {
        columnItem: updatedItem,
        checked: updatedValue,
      });
    }
    else {
      updatedColumnList.push({
        columnItem: updatedItem,
        checked: updatedValue,
      });
      setCheckedAll(updatedValue)
    }
    switch (inputGroupName) {
      case SELECTED_COLUMNS:
        setSelectedColumnsList(updatedColumnList);
        break;
      case BUSINESS_KEY:
        setBusinessKeyColumnList(updatedColumnList);
        break;
      case SKIP_IN_SYSHASH:
        setSkipSyshashColumnList(updatedColumnList);
        break;
      case SYS_SEQUENCER:
        setSysSequencerColumnList(updatedColumnList);
        break;
      case DRIVING_KEY:
        setDrivingKeyColumnList(updatedColumnList);
        break;
      default:
        break;
    }
  };

  const handleBusinessKeyObjectListChange = (
    updatedItem,
    updatedValue,
    inputGroupName
  ) => {
    const updatedColumnList = [];
    const currentList = linkBusinessKeyObject[inputGroupName];
    let checkedItemCount = 0;
    currentList.forEach((columnListItem) => {
      if (columnListItem.columnItem !== updatedItem)
        updatedColumnList.push(columnListItem);
      if (columnListItem.checked) checkedItemCount += 1;
    });
    if (updatedValue === true)
      updatedColumnList.splice(checkedItemCount, 0, {
        columnItem: updatedItem,
        checked: updatedValue,
      });
    else
      updatedColumnList.push({
        columnItem: updatedItem,
        checked: updatedValue,
      });
    setLinkBusinessKeyObject((prevState) => {
      const object = { ...prevState };
      object[inputGroupName] = updatedColumnList;
      return { ...object };
    });
  };

  const handleRadioChange = (event) => {
    const [radioLabel, radioValue] = event.target.id.split("_");
    switch (radioLabel) {
      case "deleted-field-indicator":
        updateNodeSettings(
          SOURCE_DELETED_FIELD_INDICATOR,
          radioValue === "Yes" ? true : false
        );
        break;
      case "deleted-field-name-indicator":
        updateNodeSettings(
          TALEND_DELETED_FIELD_INDICATOR,
          radioValue === "Yes" ? true : false
        );
        break;
      case "satellite-rename":
        if (radioValue === "Yes") setSatelliteRenameIndicator(true);
        else {
          updateNodeSettings(SATELLITE_UPDATED_LABEL, "");
          setSatelliteRenameIndicator(false);
        }
        break;
      case "msat-indicator":
        if (radioValue === "Yes") setMsatIndicator(true);
        else {
          setMsatIndicator(false);
          const resetColumnList = columnsList.map((columnItem) => {
            return { columnItem, checked: false };
          });
          setSysSequencerColumnList(resetColumnList);
        }
        break;
      case "sys-hash-indicator":
        setSysHashIndicator(radioValue === "Yes" ? true : false);
      default:
        break;
    }
  };

  const handleOverloadSourceTableObject = (value, table) => {
    setOverloadSourceTableObject((prevState) => {
      const object = { ...prevState };
      object[table] = value;
      return { ...object };
    });
  };

  const deleteOverloadSourceTable = (table) => {
    setOverloadSourceTableObject((prevState) => {
      const object = { ...prevState };
      delete object[table];
      return { ...object };
    });
  };

  return (
    <Modal
      show={nodeSettingsModalShow}
      onHide={onHide}
      backdrop="static"
      size="lg"
      aria-labelledby="node-settings-modal"
      centered
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title id="node-settings-modal" className="ps-4">
          {label}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="settings-modal-body">
        {(type === "hubnode" || "refnode") &&
          collapsibleItems.map((item) => {
            return (
              <CollapsibleBar title={item.table_name}>
                {Object.keys(item.collapsibleKeys).map((key) => {
                  return (
                    <CollapsibleBar title={key}>
                      <h3>Transfer List Component</h3>
                    </CollapsibleBar>
                  );
                })}
              </CollapsibleBar>
            );
          })}

        <Accordion className="node-settings-accordion" defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="node-settings-accordion-title"
          >
            Table settings
          </AccordionSummary>
          <AccordionDetails className="node-settings-accordion-details">
            <div className="mb-3">
              <label className="node-settings-label">
                Dragged connection type
              </label>
              <div className="node-settings-readonly-textfield">
                {
                  nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
                  CONNECTION_TYPE
                  ]
                }
              </div>
            </div>

            <div className="mb-3">
              <label className="node-settings-label">
                Dragged source table
              </label>
              <div className="node-settings-readonly-textfield">
                {nodeSettingsModalCurrentNode[DATA][DATABASE]}.
                {nodeSettingsModalCurrentNode[DATA][SCHEMA]}.
                {nodeSettingsModalCurrentNode[DATA][SOURCE_TABLE]}
              </div>
            </div>

            {(type === "satnode" ||
              "lsatnode" ||
              "xrefnode" ||
              "xlinknodedelta" ||
              "xlinknodefull") &&
              type !== "hubnode" &&
              type !== "refnode" && (
                <div className="mb-3">
                  <label className="node-settings-label mb-2">
                    Select table abbreviation
                  </label>
                  <CustomDropdown
                    dropdownList={separatorList}
                    dropdownName="Table abbreviation"
                    handleSelection={(value) =>
                      updateNodeSettings(TABLE_ABBREVIATION, value)
                    }
                    selectedValue={nodeSettings[TABLE_ABBREVIATION]}
                    filterable={false}
                    clipLength={30}
                    customClassname="custom-dropdown-styles"
                  />
                </div>
              )}

            {nodeSettings[TABLE_ABBREVIATION] !== undefined &&
              nodeSettings[TABLE_ABBREVIATION] !== "" &&
              Object.keys(overloadSourceTableObject).length === 0 &&
              renderRenameOrXlinkName(type)}
            {satelliteRenameIndicator && (
              <Form
                className="rename-form"
                onSubmit={(event) => event.preventDefault()}
              >
                <InputGroup>
                  <Form.Control
                    type="text"
                    onChange={(event) =>
                      updateNodeSettings(
                        SATELLITE_UPDATED_LABEL,
                        removeSpecialSymbols(event.target.value).toUpperCase()
                      )
                    }
                    value={
                      nodeSettings[SATELLITE_UPDATED_LABEL] !== undefined
                        ? nodeSettings[SATELLITE_UPDATED_LABEL]
                        : ""
                    }
                    placeholder="Enter satellite name"
                    disabled={!currentDesign.authorized}
                    autoFocus
                  />
                  {/* <Button onClick={updateSatelliteLabel}>Submit</Button> */}
                </InputGroup>
                <p className="rename-form-helper-text">Type name here</p>
              </Form>
            )}

            {(type === "hubnode" || type === "refnode") && (
              <div className="mb-2">
                <label className="node-settings-label mb-2">Rename table</label>
                <Form
                  className="rename-form"
                  onSubmit={(event) => event.preventDefault()}
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      onChange={(event) =>
                        updateNodeSettings(
                          HUB_NAME_UPDATED_LABEL,
                          event.target.value.toUpperCase()
                        )
                      }
                      value={
                        nodeSettings[HUB_NAME_UPDATED_LABEL] !== undefined
                          ? nodeSettings[HUB_NAME_UPDATED_LABEL]
                          : ""
                      }
                      placeholder="Enter table name"
                      disabled={!currentDesign.authorized}
                      autoFocus
                    />
                  </InputGroup>
                </Form>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        {/* TABEL SETTINGS ENDS */}

        {(type === "satnode" ||
          "lsatnode" ||
          "xrefnode" ||
          "xlinknodedelta" ||
          "xlinknodefull") &&
          type !== "hubnode" &&
          type !== "refnode" && (
            <>
              {/* OBJECT SCHEMA SETTINGS STARTS */}
              <Accordion className="node-settings-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="node-settings-accordion-title"
                >
                  Object schema settings
                </AccordionSummary>
                <AccordionDetails className="node-settings-accordion-details">
                  <div className="mb-3">
                    <div className="mb-3">
                      <label className="node-settings-label mb-2">
                        Select Object Schema
                      </label>
                      <CustomDropdown
                        dropdownList={objectSchemasList}
                        // dropdownName={(nodeSettings[OBJECT_SCHEMA] !== undefined && nodeSettings[OBJECT_SCHEMA] !== '') ? `${nodeSettings[OBJECT_SCHEMA]}` : "Object Schema"}
                        dropdownName="Object Schema"
                        handleSelection={(value) =>
                          updateNodeSettings(OBJECT_SCHEMA, value)
                        }
                        selectedValue={nodeSettings[OBJECT_SCHEMA]}
                        filterable={false}
                        clipLength={30}
                        customClassname="custom-dropdown-styles"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="node-settings-label mb-2">
                        Select Object
                      </label>
                      <CustomDropdown
                        dropdownList={objectsList}
                        // dropdownName={(nodeSettings[OBJECT] !== undefined && nodeSettings[OBJECT] !== '') ? `${nodeSettings[OBJECT]}` : "Object"}
                        dropdownName="Object"
                        handleSelection={(value) =>
                          updateNodeSettings(OBJECT, value)
                        }
                        selectedValue={nodeSettings[OBJECT]}
                        filterable={true}
                        clipLength={30}
                        customClassname="custom-dropdown-styles"
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* OBJECT SCHEMA SETTINGS ENDS */}

              {/* COLUMN SETTINGS - SELECTED COLUMN STARTS */}
              <Accordion className="node-settings-accordion" disabled={disableAccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="node-settings-accordion-title"
                >
                  Select columns you want to include in the table
                </AccordionSummary>
                <AccordionDetails className="node-settings-accordion-details">
                  {renderSelectedColumnList(type)}
                </AccordionDetails>
              </Accordion>
              {/* COLUMN SETTINGS - SELECTED COLUMN ENDS */}


              {/* COLUMN SETTINGS - BUSINESS KEY STARTS */}
              <Accordion className="node-settings-accordion" disabled={disableAccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="node-settings-accordion-title"
                >
                  Column settings - Business Key
                </AccordionSummary>
                <AccordionDetails className="node-settings-accordion-details">
                  {renderBusinessKeys(type)}
                </AccordionDetails>
              </Accordion>
              {/* COLUMN SETTINGS - BUSINESS KEY ENDS */}

              {/* COLUMN SETTINGS - SKIPPING COLUMN IN SYS_HASH STARTS */}
              {!(
                nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
                CONNECTION_TYPE
                ] === "HUB||SAT||LINK||LSAT" && type === "lsatnode"
              ) &&
                skipSyshashColumnList.length > 0 && (
                  <Accordion className="node-settings-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="node-settings-accordion-title"
                    >
                      Column settings - Skipping columns in SYS_HASH
                    </AccordionSummary>
                    <AccordionDetails className="node-settings-accordion-details">
                      <div className="mb-2">
                        <label className="node-settings-label mb-2">
                          Do you want to skip columns in the SYS_HASH :
                        </label>
                        <Form onSubmit={(event) => event.preventDefault()}>
                          {["Yes", "No"].map((value) => {
                            return (
                              <Form.Check
                                inline
                                type="radio"
                                id={`sys-hash-indicator_${value}`}
                                label={value}
                                onChange={handleRadioChange}
                                checked={
                                  value === "Yes"
                                    ? sysHashIndicator
                                    : !sysHashIndicator
                                }
                                disabled={!currentDesign.authorized}
                              />
                            );
                          })}
                        </Form>
                      </div>

                      {sysHashIndicator && (
                        <div className="my-2">
                          {skipSyshashColumnList.length > 0 && (
                            <>
                              <p className="node-settings-label mb-2">
                                Select the columns you want to skip in the
                                SYS_HASH
                              </p>
                              <CustomCheckboxList
                                inputGroupName={`${SKIP_IN_SYSHASH}`}
                                checkboxList={skipSyshashColumnList}
                                filterable={true}
                                handleCheckboxListChange={handleListChange}
                                updateListOrder={setSkipSyshashColumnList}
                                isSortable={true}
                                title="Select data type casts for your columns"
                              />
                            </>
                          )}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
              {/* COLUMN SETTINGS - SKIPPING COLUMN IN SYS_HASH STARTS */}

              {/* DATA TYPE CASTS STARTS */}
              {!(type === "hubnode" || type === "refnode") && (nodeSettings[OBJECT_SCHEMA] && nodeSettings[OBJECT]) &&
                currentDesign?.designId !== USER_TUTORIAL ? (
                <Accordion className="node-settings-accordion" disabled={disableAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="node-settings-accordion-title"
                  >
                    Data Type Casts
                  </AccordionSummary>
                  <AccordionDetails className="node-settings-accordion-details">
                    <ColumnSettingsModal
                      currentNode={nodeSettingsModalCurrentNode}
                      authorized={currentDesign.authorized}
                      ref={columnSettingsRef}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {/* DATA TYPE CASTS ENDS */}

              {/* DELETED RECORDS STARTS */}
              <Accordion className="node-settings-accordion" disabled={disableAccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="node-settings-accordion-title"
                >
                  Deleted Records
                </AccordionSummary>
                <AccordionDetails className="node-settings-accordion-details">
                  {!(
                    nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
                    CONNECTION_TYPE
                    ] === "HUB||SAT||LINK||LSAT" && type === "lsatnode"
                  ) && (
                      <div className="mb-2">
                        <label className="node-settings-label mb-2">
                          The source system has deleted record :
                        </label>
                        <Form onSubmit={(event) => event.preventDefault()}>
                          {["Yes", "No"].map((value) => {
                            return (
                              <Form.Check
                                inline
                                type="radio"
                                id={`deleted-field-indicator_${value}`}
                                label={value}
                                onChange={handleRadioChange}
                                checked={
                                  value === "Yes"
                                    ? nodeSettings[SOURCE_DELETED_FIELD_INDICATOR]
                                    : !nodeSettings[
                                    SOURCE_DELETED_FIELD_INDICATOR
                                    ]
                                }
                                disabled={!currentDesign.authorized}
                              />
                            );
                          })}
                        </Form>
                      </div>
                    )}

                  {!nodeSettings[SOURCE_DELETED_FIELD_INDICATOR] &&
                    !(
                      nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
                      CONNECTION_TYPE
                      ] === "HUB||SAT||LINK||LSAT" && type === "lsatnode"
                    ) && (
                      <div className="mb-2">
                        <label className="node-settings-label mb-2">
                          Deleted field indicator inserted during a talend job
                          and is NOT in the schema in the source system (does
                          not land in the PSA schema table) :
                        </label>
                        <Form onSubmit={(event) => event.preventDefault()}>
                          {["Yes", "No"].map((value) => {
                            return (
                              <Form.Check
                                inline
                                type="radio"
                                id={`deleted-field-name-indicator_${value}`}
                                label={value}
                                onChange={handleRadioChange}
                                checked={
                                  value === "Yes"
                                    ? nodeSettings[
                                    TALEND_DELETED_FIELD_INDICATOR
                                    ]
                                    : !nodeSettings[
                                    TALEND_DELETED_FIELD_INDICATOR
                                    ]
                                }
                                disabled={!currentDesign.authorized}
                              />
                            );
                          })}
                        </Form>
                      </div>
                    )}

                  {nodeSettings[SOURCE_DELETED_FIELD_INDICATOR] &&
                    !(
                      nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
                      CONNECTION_TYPE
                      ] === "HUB||SAT||LINK||LSAT" && type === "lsatnode"
                    ) && (
                      <div className="mb-2">
                        <label className="node-settings-label mb-2">
                          Select Source deleted field name
                        </label>
                        <CustomDropdown
                          dropdownList={columnsList}
                          dropdownName="Column Name"
                          handleSelection={(value) =>
                            updateNodeSettings(SOURCE_DELETED_FIELD_NAME, value)
                          }
                          selectedValue={
                            nodeSettings[SOURCE_DELETED_FIELD_NAME]
                          }
                          filterable={true}
                          clipLength={30}
                          customClassname="custom-dropdown-styles"
                        />
                      </div>
                    )}
                </AccordionDetails>
              </Accordion>
              {/* DELETED RECORDS ENDS */}

              {/* OTHER SETTINGS STARTS */}
              {(Object.keys(overloadSourceTableObject).length > 0 ||
                (type === "lsatnode" && drivingKeyColumnList.length > 0) ||
                (type === "satnode" &&
                  nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
                  CONNECTION_TYPE
                  ] !== "REF||SAT" &&
                  sysSequencerColumnList.length > 0)) && (
                  <Accordion className="node-settings-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="node-settings-accordion-title"
                    >
                      Other Settings
                    </AccordionSummary>
                    <AccordionDetails className="node-settings-accordion-details">
                      {type === "satnode" &&
                        nodeSettingsModalCurrentNode[DATA][DRAGGED_CONNECTION][
                        CONNECTION_TYPE
                        ] !== "REF||SAT" &&
                        sysSequencerColumnList.length > 0 && (
                          <div className="mb-2">
                            <label className="node-settings-label mb-2">
                              Do you want to make MSAT :
                            </label>
                            <Form onSubmit={(event) => event.preventDefault()}>
                              {["Yes", "No"].map((value) => {
                                return (
                                  <Form.Check
                                    inline
                                    type="radio"
                                    id={`msat-indicator_${value}`}
                                    label={value}
                                    onChange={handleRadioChange}
                                    checked={
                                      value === "Yes"
                                        ? msatIndicator
                                        : !msatIndicator
                                    }
                                    disabled={!currentDesign.authorized}
                                  />
                                );
                              })}
                            </Form>
                          </div>
                        )}

                      {msatIndicator && (
                        <div className="my-2">
                          <label className="node-settings-label mb-2">
                            Select the columns for the Sys Sequencer and drag them
                            in the desired order
                          </label>
                          <CustomCheckboxList
                            inputGroupName={`${SYS_SEQUENCER}`}
                            checkboxList={sysSequencerColumnList}
                            filterable={true}
                            handleCheckboxListChange={handleListChange}
                            updateListOrder={setSysSequencerColumnList}
                            isSortable={true}
                            title="Select data type casts for your columns"
                          />
                        </div>
                      )}

                      {type === "lsatnode" && drivingKeyColumnList.length > 0 && (
                        <>
                          <div className="mb-2">
                            <label className="node-settings-label mb-2">
                              Driving Key Name
                            </label>
                            <Form onSubmit={(event) => event.preventDefault()}>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  onChange={(event) =>
                                    updateNodeSettings(
                                      DRIVING_KEY_LABEL,
                                      event.target.value.toUpperCase()
                                    )
                                  }
                                  value={
                                    nodeSettings[DRIVING_KEY_LABEL] !== undefined
                                      ? nodeSettings[DRIVING_KEY_LABEL]
                                      : ""
                                  }
                                  placeholder="Enter driving key name"
                                  disabled={!currentDesign.authorized}
                                />
                              </InputGroup>
                            </Form>
                          </div>
                          <div className="mb-2">
                            <label className="node-settings-label mb-2">
                              Select the columns for the Driving Key and drag them
                              in the desired order
                            </label>
                            <CustomCheckboxList
                              inputGroupName={`${DRIVING_KEY}`}
                              checkboxList={drivingKeyColumnList}
                              filterable={true}
                              handleCheckboxListChange={handleListChange}
                              updateListOrder={setDrivingKeyColumnList}
                              isSortable={true}
                              title="Select data type casts for your columns"
                            />
                          </div>
                        </>
                      )}
                      {Object.keys(overloadSourceTableObject).length > 0 && (
                        <div className="my-2">
                          <label className="node-settings-label my-3">
                            Overloaded source tables
                          </label>
                          {Object.keys(overloadSourceTableObject).map(
                            (loadedTable) => {
                              return (
                                <div key={loadedTable}>
                                  <label className="node-settings-label mb-2">
                                    {loadedTable}
                                  </label>
                                  <div className="d-flex align-items-center">
                                    <CustomDropdown
                                      dropdownList={objectsList}
                                      dropdownName="Select Object"
                                      handleSelection={(value) =>
                                        handleOverloadSourceTableObject(
                                          value,
                                          loadedTable
                                        )
                                      }
                                      selectedValue={
                                        overloadSourceTableObject[loadedTable]
                                      }
                                      filterable={true}
                                      clipLength={30}
                                      customClassname="custom-dropdown-styles"
                                    />
                                    <span>
                                      <CancelRoundedIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          deleteOverloadSourceTable(loadedTable)
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
              {/* OTHER SETTINGS ENDS */}
            </>
          )}
      </Modal.Body>
      <Modal.Footer className="border-0 mx-4">
        <Button variant="outline-primary" onClick={onSave}>
          Save
        </Button>
        <Button variant="outline-secondary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NodeSettingsModal
