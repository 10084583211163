import React, { useState, useEffect } from "react";
import { useSocketioContext } from "../Context/SocketioContext";

const HistorySideBar = ({ reactFlowWrapper, currentDesign, isHistorySidebarOpen, user }) => {
  const [actionMessages, setActionMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { socket } = useSocketioContext();
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [messageLimit, setMessageLimit ] = useState(6);
  const [seeMore, setSeeMore] = useState(false)
  
  useEffect(() => {
    if (isHistorySidebarOpen) {
      setActionMessages([]); 
      setHasMoreMessages(true);
      fetchActionMessages(6); 
    } else {
      
      setMessageLimit(6);
    }
  }, [isHistorySidebarOpen]);

  useEffect(() => {
    if (socket) {
      socket.on("receiveActionMessages", (newMessages) => {
        if (newMessages?.total === newMessages?.data?.length ) {
          setHasMoreMessages(false);
        }
  
        if (isHistorySidebarOpen) {
          setActionMessages((prevMessages) => {
            const reversedMessages = [];
            const orderedMessages = [];
  
            newMessages?.data?.forEach((message) => {
              if (message?.IS_UNDONE) {
                reversedMessages.unshift(message);  
              } else {
                orderedMessages.push(message);  
              }
            });
  
          
            const newUniqueMessages = [...reversedMessages, ...orderedMessages];
  
            if (seeMore) {
              setSeeMore(false);
              return [...newUniqueMessages];
            }
  
            return [...newUniqueMessages, ...prevMessages];
          });
          setLoading(false);
        }
      });

    
  
      return () => {
        socket.off("receiveActionMessages");
      };
    }
  }, [socket, isHistorySidebarOpen, seeMore]);
  
  const fetchActionMessages = (limit) => {
    setLoading(true);
    const tableId = currentDesign?.designId;
    const userId = user?.attributes?.name;

    socket.emit("getActionMessages", { tableId, userId, limit });
  };

  
  const handleSeeMore = () => {
    if (!loading && hasMoreMessages) {
      setSeeMore(true);
      const newLimit = messageLimit + 6; 
      setMessageLimit(newLimit); 
      fetchActionMessages(newLimit); 
    }
  };
  
  return (
    <div className="history-sidebar d-flex flex-column h-100">
      <div className="history-header">
        <h4>Change History</h4>
      </div>
      <div className="history-log-list">
        {actionMessages.length > 0 ? (
          <ul>
            {actionMessages.map((message, index) => (
              <li key={index}>{message?.ACTION_MESSAGE}</li>
            ))}
          </ul>
        ) : (
          <p>No history to display</p>
        )}
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
         hasMoreMessages && (
          <button className="see-more-btn" onClick={handleSeeMore}>
            See More
          </button>
        )
      )}
    </div>
  );
};

export default HistorySideBar;
