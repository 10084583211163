import { Link } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import { useDesignsContext } from "../Context/DesignsContext";
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from "@mui/material";
import { UNDO, REDO } from '../Types/ActionTypes';
import { AUTOSAVING, LOADING, SYNCING } from '../Types/DesignStatusTypes'; 
import { USER_TUTORIAL } from "../Types/MenuTypes"; 
import { useEffect, useState } from "react";
import { useSocketioContext } from "../Context/SocketioContext";
import { useAuth } from "../Context/AuthContext";
import TransparentTextField from "./TransparentTextField";
import LogoutDropdown from './LogoutDropdown';
import { ReactComponent as RocheLogoWhite } from '../Assets/svg/RocheLogoWhite.svg';
import Badge from '@mui/material/Badge'; 

const ActionBar = ({ activeStep, toggleHistorySidebar, isHistorySidebarOpen }) => {
  const { socket } = useSocketioContext();
  const { currentDesign, designRename, currentDesignActiveUsers, setCurrentDesignActiveUsers, undoDesign, redoDesign, actionCount, setActionCount, setMessageLimit} = useDesignsContext();
  const { snowflakeAccount } = useAuth();
  const [showBadge, setShowBadge] = useState(true);  
  const syncStatusMessage = "Designing Sync Status";
  useEffect(() => {
    if(socket !== null && socket !== undefined) {
      socket.on('updateDesignActiveUsers', (usersList) => {
        setCurrentDesignActiveUsers(usersList);
      });
    }
  }, [socket]);

  useEffect(()=> {
    if (actionCount > 0) {
      setShowBadge(true)
    }
  },[actionCount])


  const handleClick = async (option) => {
    switch(option) {
      case UNDO:
        undoDesign();
        break;
      case REDO:
        redoDesign();
        break;
      default:
        break;
    }
  }

  const showUndoAndRedoButtons = currentDesign.authorized && (activeStep === 0);
 
  const handleHistoryClick = () => {
    if (isHistorySidebarOpen) {
    setShowBadge(false);  
    setActionCount(0)
    }

    toggleHistorySidebar();  
  }

  return (
    <Stack direction="horizontal" gap={2} className="px-4 bg-primary text-white h-100">
      <Link to="/" >
        <span role="button">
          <RocheLogoWhite className="navbar-logo"/>
        </span>
      </Link>
      <div className="ms-4">
        {currentDesign.designName !== USER_TUTORIAL 
          ? (
            <TransparentTextField 
              inputText={currentDesign?.businessName }
              updateTextHandler={value => designRename(value)}
              authorized={currentDesign?.authorized}
            />
          )
          : `SANDBOX ENVIRONMENT in ${snowflakeAccount}`}
      </div>
      {currentDesign.designName !== USER_TUTORIAL && (
        <div role="button" className='ms-4 pe-1'>
          <Tooltip 
            title={currentDesignActiveUsers?.length > 0 ? (
              currentDesignActiveUsers.join(', ')
            ) : 'No more users working on this design'} 
            placement="bottom" 
            arrow
          >
            <PeopleAltIcon />
          </Tooltip>
        </div>
      )}
      
      {showUndoAndRedoButtons ? (
        <>
          <div role="button" className="ms-auto" onClick={() => handleClick(UNDO)}>
            <Tooltip title="Undo" placement="bottom-end" arrow>
              <UndoRoundedIcon />
            </Tooltip>
          </div>
          <div role="button" className='ms-1 pe-1' onClick={() => handleClick(REDO)}>
              <Tooltip title="Redo" placement="bottom-start" arrow>
                <RedoRoundedIcon />
              </Tooltip>
          </div>
          <div className="vr"></div>
        </>) : <div className="ms-auto"></div>
      }

      <div className={currentDesign.authorized ? 'ms-2' : 'ms-auto'}>
        <Tooltip 
          title={syncStatusMessage} 
          placement="bottom" 
          arrow 
        >
          <div
            className="d-inline-block"
            style={{ cursor: 'pointer' }} 
          >
            <Typography>{currentDesign.savingStatus}</Typography> 
          </div>
        </Tooltip>
      </div>
      
      {
        (currentDesign.savingStatus === AUTOSAVING || 
          currentDesign.savingStatus === LOADING || 
          currentDesign.savingStatus === SYNCING) ? (
          <>
            <div className="spinner-border spinner-border-sm ms-1 me-2">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        ) : (
          <div className="me-2">
            <div
              className="d-inline-block"
              style={{ cursor: 'pointer' }} 
              onClick={handleHistoryClick}  // Hide badge when clicked
            >
              <Tooltip title="View design change history." placement="bottom" arrow>
              <Badge 
                badgeContent={actionCount > 0 ? actionCount : null}  
                color="secondary" 
                invisible={actionCount <= 0 || !showBadge || isHistorySidebarOpen}  
              >
                <HistoryRoundedIcon />
              </Badge>
             
              </Tooltip>
            </div>
          </div>
        )
      }

      <div>
        <LogoutDropdown top="45" />
      </div>
    </Stack>
  );
}

export default ActionBar;
