import { useEffect, useState, useRef } from 'react';
import { useModalContext } from '../../../Context/ModalContext';
import { Handle } from 'react-flow-renderer';
import './CustomNodesStyle.css';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { v4 as uuidv4 } from 'uuid';
import {
  DATA,
  LABEL,
  OVERLOAD_SOURCE_TABLES_OBJECT,
  USER_SETTINGS,
  TABLE_ABBREVIATION,
  DRAGGED_CONNECTION,
  CONNECTED_ENTITIES,
  CONNECTION_TYPE,
  BUSINESS_KEY,
  SOURCE_DELETED_FIELD_INDICATOR,
  TALEND_DELETED_FIELD_INDICATOR,
  SATELLITE_UPDATED_LABEL,
  SOURCE_DELETED_FIELD_NAME,
  SELECTED_COLUMNS,
} from './NodeLinkTypes';
import { usePlaygroundContext } from '../../../Context/PlaygroundContext';
import { useReactFlowContext } from '../../../Context/reactFlowContext';
import { useAuth } from '../../../Context/AuthContext';
import { ACTION_MESSAGES } from '../../../Types/ActionMessageTypes';

const SatNode = (node) => {
  const { reactFlowInstance } = useReactFlowContext()
  const { enableNodeSettingsModalShow, setNodeSettingsModalCurrentNode } = useModalContext()
  const { selectedElements, elements, setElements, onElementsRemove, onConnect, updateDesign } = usePlaygroundContext()
  const { label } = node[DATA]
  const [overloaded, setOverloaded] = useState(false)
  const [warning, setWarning] = useState(false) 
  const reactFlowSatWrapper = useRef(null);
  const {user} = useAuth();
  const username = `${user?.attributes?.given_name} ${user?.attributes?.family_name}`

  const onDelete = () => {
    onElementsRemove([node])
  }
  const onClone = (event) => {
    let cloneUser = JSON.parse(JSON.stringify(node));
    const reactFlowBounds = reactFlowSatWrapper.current.getBoundingClientRect();
    let cloneID = `SAT_${uuidv4()}`;
    let cloneLabel = `${cloneUser[DATA][LABEL]}_COPY`;
    cloneUser[DATA]['id'] = cloneID;
    cloneUser['id'] = cloneID;
    cloneUser[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][1] = cloneID;
    cloneUser[DATA][LABEL] = cloneLabel;
    cloneUser[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] = `${cloneUser[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL]}_COPY`;;
    let satelliteNodePosition = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: (event.clientY - reactFlowBounds.top),
    });
    let hubID = cloneUser[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][0];
    setElements([...elements, { ...cloneUser, position: satelliteNodePosition }, {
      id: `edge_${hubID}_${cloneID}`,
      source: cloneID,
      target: cloneUser[DATA][DRAGGED_CONNECTION][CONNECTED_ENTITIES][0],
      type: 'default',
    }]);
    let actionType = 'NODE_CLONED';  
    let message = ACTION_MESSAGES[actionType].message({ user:username, originalNodeName: cloneUser[DATA][LABEL] });
    setTimeout(() => {
      updateDesign(message)
    }, 2000)
  }

  useEffect(() => {
    if (node[DATA][USER_SETTINGS] !== undefined) {
      switch (true) {
        case (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === undefined):
        case (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === ''):
        case (node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === undefined):
        case (node[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR] === undefined):
        case (node[DATA][USER_SETTINGS][SELECTED_COLUMNS] === undefined || node[DATA][USER_SETTINGS][SELECTED_COLUMNS].length < 1):
        case (node[DATA][USER_SETTINGS][BUSINESS_KEY].length < 1 && !(node[DATA][DRAGGED_CONNECTION][CONNECTION_TYPE] === "HUB||SAT||LINK||LSAT")):
        case (node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true && node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME] === ''):
          setWarning(true);
          break
        default:
          setWarning(false);
          break;
      }
      if(!(node[DATA][USER_SETTINGS].hasOwnProperty(SELECTED_COLUMNS))){
        if((node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== undefined) && (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== '') && warning === false){
          setWarning(false);
        }
      }
      if (node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined && Object.keys(node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).length > 0 && warning === false) {
        if (node[DATA][USER_SETTINGS][SATELLITE_UPDATED_LABEL] === '') {
          setWarning(true)
        }
        Object.keys(node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).forEach(loadedTable => {
          if (node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT][loadedTable] === '') {
            setWarning(true)
          }
        })
        const overloadedValues = Object.values(node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]);
        const overloadedSet = new Set(overloadedValues);

        if (overloadedSet.size !== overloadedValues.length) {
          setWarning(true);
        }

        overloadedValues.forEach(tableValue => {
          if (node[DATA].userSettings.object === tableValue) {
            setWarning(true)
          }
        })
      }
    } else {
      setWarning(true)
    }
  }, [node[DATA][USER_SETTINGS]])

  useEffect(() => {
    if (node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined && Object.keys(node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).length > 0) {
      setOverloaded(true)
    } else setOverloaded(false)
  }, [node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]])

  const onClick = () => {
    setNodeSettingsModalCurrentNode(node)
    enableNodeSettingsModalShow(node.id)
  }

  return (
    <div className="satNode">
      <Handle
        type="source"
        position='bottom'
        style={{ background: '#555' }}
      />
      <div className="py-1 px-2" ref={reactFlowSatWrapper} >
        {label}
        <span className="position-absolute top-100 start-100 translate-middle badge fs-6" hidden={!overloaded}>
          <Tooltip title="Overloaded satellite" placement="bottom-start" arrow>
            <AutoAwesomeMotionIcon sx={{ fontSize: 20, color: '#f19d32' }} />
          </Tooltip>
        </span>
        <span className="position-absolute top-0 start-100 translate-middle badge fs-6" hidden={!warning}>
          <Tooltip
            title="Node Settings incomplete."
            placement="bottom-start"
            arrow
          >
            <ErrorTwoToneIcon sx={{ fontSize: 25, color: 'red' }} />
          </Tooltip>
        </span>
        <span className="ms-2" onClick={onClick} style={{ cursor: 'pointer' }}>
          <Tooltip
            title="Node settings"
            placement="bottom-end"
            arrow
          >
            <SettingsIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
          </Tooltip>
        </span>
        <span className="ms-2" onClick={onClone} style={{ cursor: 'pointer' }} hidden={warning}>
          <Tooltip
            title="You can clone Node"
            placement="bottom-end"
            arrow
          >
            <ContentCopyIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
          </Tooltip>
        </span>
        {node.selected && (
          <span className="ms-2" onClick={onDelete} style={{ cursor: 'pointer' }}>
            <Tooltip
              title="Delete node along with its dependents"
              placement="bottom-start"
              arrow
            >
              <DeleteForeverIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
            </Tooltip>
          </span>
        )
        }
      </div>
    </div>
  );
}

export default SatNode;